const internet = [
  {
    id: 'internet-start',
    title: "Start Xtra",
    downloadSpeed: 100,
    uploadSpeed: 30,
    price: 4250,
    discountedPrice: 2250,
    discountedPricePeriodMeta: 12,
    items: [
      { title: "100 Mbit/s download" },
      { title: "30 Mbit/s upload" },
      { title: "Safe Online: 1 licentie" },
      { title: "Wifi Back-up Zakelijk" },
      { title: "Business Crew" },
      { title: "Unlimited Learning" },
      { title: "2 SmartWifi pods", /*notincluded: false*/ },
    ]
  },
  {
    id: 'internet-complete',
    title: "Complete Xtra",
    downloadSpeed: 500,
    uploadSpeed: 40,
    price: 4750,
    discountedPrice: 2625,
    discountedPricePeriodMeta: 12,
    items: [
      { title: "500 Mbit/s download" },
      { title: "40 Mbit/s upload" },
      { title: "Safe Online: 3 licenties" },
      { title: "Wifi Back-up Zakelijk" },
      { title: "Business Crew" },
      { title: "Unlimited Learning" },
      { title: "2 SmartWifi pods" },
    ]
  },
  {
    id: 'internet-giga',
    title: "Giga Xtra",
    downloadSpeed: 1000,
    uploadSpeed: 50,
    price: 6000,
    discountedPrice: 3250,
    discountedPricePeriodMeta: 12,
    items: [
      { title: "1000 Mbit/s download" },
      { title: "50 Mbit/s upload" },
      { title: "Safe Online: 5 licenties" },
      { title: "Wifi Back-up Zakelijk" },
      { title: "Business Crew" },
      { title: "Unlimited Learning" },
      { title: "2 SmartWifi pods" },
    ]
  },
];

const tv = [
  {
    id: 'tv-start',
    title: "TV Start Next",
    price: 550,
    digitalItems: [
      { title: "80+ Kanalen" },
      { title: "Mediabox Next (4K)" },
      { title: "Inc. Ziggo Sport" },
      { title: "Overal tv met Ziggo GO" },
      { title: "Verderkijken op elk scherm" },
      { title: "Kijkprofiel & Voice Control" },
      { title: "Terugkijken: 2 dagen" },
      { title: "Opnemen & live-tv pauzeren", notincluded: true },
      { title: " Movies & Series", notincluded: true },
    ],
  },
  {
    id: 'tv-complete',
    title: "TV Complete Next",
    price: 1500,
    digitalItems: [
      { title: "80+ Kanalen" },
      { title: "Mediabox Next (4K)" },
      { title: "Inc. Ziggo Sport" },
      { title: "Overal tv met Ziggo GO" },
      { title: "Verderkijken op elk scherm" },
      { title: "Kijkprofiel & Voice Control" },
      { title: "Terugkijken: 7 dagen" },
      { title: "Opnemen & live-tv pauzeren" },
      { title: " Movies & Series" },
    ],
  },
  {
    id: 'tv-kabel',
    title: "Kabel TV",
    price: 0,
    digitalItems: [
      { title: "50+ Kanalen", info: "<div>Overal tv met ZIGGO GO<div>" },
      { title: "Alleen tv-signaal (geen Mediabox)" },
      { title: "Inc. Ziggo Sport" },
      { title: "Overal tv met Ziggo GO" },
    ]
  },
];

const phone = [
  {
    id: 'phone-vast',
    title: "Zakelijk vast bellen",
    price: 250,
    items: [
      { title: "12 cent per minuut" },
      { title: "Nummerbehoud" },
    ]
  },
  {
    id: 'phone-geen',
    title: "Geen telefonie",
    price: 0,
  }
];

const extra = [
  {
    id: 'gratisbellen',
    title: "Onbeperkt Bellen Nationaal",
    price: 1500,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
  {
    id: 'sport',
    title: "Ziggo Sport Totaal",
    price: 1236,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
  {
    id: 'ESPN compleet',
    title: "ESPN compleet",
    price: 1483,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
  {
    id: 'movies',
    title: "Movies & Series XL",
    price: 988,
    discountedPrice: 0,
    discountedPricePeriodMeta: 12,
    
  },
];

export {
  internet as internetProducts,
  tv as tvProducts,
  phone as phoneProducts,
  extra as extraProducts,
}
